<template>
  <div class="mail-folder">
    <MailFiltration :title="folderTitle" :folder="folder" v-if="!$route.params.message && folder"
                    :selectedMessages="selectedMessages" :page="page" :perPage="perPage"
                    @openPage="$emit('openPage', $event)" @selectAll="selectAll"
                    @updateMessages="$emit('updateMessages')"/>

    <div class="mail-skeleton" v-if="loading">
      <v-skeleton-loader
          type="table-tbody"
          width="100%"
          height="100%"
      />
    </div>
    <div v-else-if="$route.params.message" class="mail-folder__content">
      <router-view :folder="folder"/>
    </div>
    <v-data-table
        :headers="headersWithEmpty"
        :items="messages"
        class="mail-table"
        v-else
        hide-default-header
        hide-default-footer
        :show-select="hasRight('mail.action')"
        v-model="selectedMessages"
        item-key="id"
        @click:row="toMessage"
        checkbox-color="green"
        :item-class="mailBackground"
        :key="tableKey"
        disable-pagination
    >
      <template v-slot:item.from.name="{item}">
        <div class="mail-folder__name text-truncate" :title="item.from.name">{{ messageName(item) }}</div>
      </template>
      <template v-slot:item.text="{item}">
        <div class="mail-folder__text text-truncate">
          {{ item.subject }}
        </div>
      </template>
      <template v-slot:item.date="{item}">
        <div class="mail-folder__date">{{ returnDate(item.date) }}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import MailFiltration from "../../components/mail/Filtration.vue";
import {mapActions, mapState} from "vuex";
import {hasRight} from "../../utils/access/hasRight";

export default {
  name: "MailFolder",
  props: {
    folder: {
      type: Object,
    },
    page: Number,
    perPage: Number,
  },
  data() {
    return {
      headers: [
        {value: 'from.name'},
        {value: 'text'},
        {value: 'date'},
      ],
      selectedMessages: [],
      tableKey: 0,
    }
  },
  components: {MailFiltration},
  computed: {
    ...mapState("mail", ["messages", "message"]),
    ...mapState("common", ["loading",]),
    headersWithEmpty () {
      if (!hasRight('mail.action')) {
        return [{value: ''}, ...this.headers]
      }
      return this.headers;
    },
    folderTitle() {
      if (this.folder && this.folder.title) {
        return `Папка: ${this.folder.title}`;
      }
      return 'Папка: Загрузка...'
    }
  },
  methods: {
    ...mapActions({
      setFolderIndicator: 'mail/setFolderIndicator',
    }),
    hasRight,
    messageName (item) {
      const folderTypes = ['sent', 'drafts', 'outbox'];
      if (folderTypes.includes(this.folder.icon)) {
        return `Кому: ${item.to.map(i => i.address).join(', ')}`
      }
      return item.from.name || item.from.address;
    },
    toMessage(e) {
      const unseen = this.folder?.status?.unseen;
      if (this.folder?.status?.unseen)
        if (e.unseen && unseen) {
          this.setFolderIndicator({folder: this.folder, unseen: unseen - 1});
        }
      this.$router.push({name: 'MessagePage', params: {message: e.id}});
    },
    returnDate(date) {
      if (this.$moment().format("MMM Do YY") === this.$moment(date).format("MMM Do YY")) {
        return this.$moment(date).format('LT');
      } else if (this.$moment(date).format('YYYY') !== this.$moment().format("YYYY")) {
        return this.$moment(date).format('DD MMM YYYY');
      }
      return this.$moment(date).format('DD MMM');
    },
    selectAll(value) {
      if (value) {
        this.selectedMessages = [...this.messages];
      } else {
        this.selectedMessages = [];
      }
    },
    mailBackground(item) {
      if (item.unseen) {
        return 'mail-table__message mail-table__message--new';
      }
      return 'mail-table__message';
    }
  },
  watch: {
    'messages':  {
      handler () {
        this.tableKey++;
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss">
.mail {
  .mail-table {
    max-height: calc(100vh - 225px);
    overflow-y: auto;
    table {
      table-layout: fixed;
    }

    tr {
      cursor: pointer;
    }

    td:nth-of-type(1) {
      width: 60px !important;
      box-sizing: border-box;

      .v-data-table__checkbox {
        max-width: 100%;

        .v-input--selection-controls__input {
          margin-right: 0;
        }
      }
    }

    &__message {
      background: #F2F2F2;

      &--new {
        background: #fff;

        td {
          .mail-folder__date,
          .mail-folder__name,
          .mail-folder__text {
            font-weight: 700;
          }
        }
      }
    }

    td:nth-of-type(2) {
      width: 300px !important;
      box-sizing: border-box;
    }

    td:nth-of-type(3) {
      width: 100% !important;
      //max-width: 125px !important;
      box-sizing: border-box;
    }

    td:last-of-type {
      width: 140px !important;
      box-sizing: border-box;
    }
  }
}

.mail-folder__date {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
}

.mail-folder__name {
  font-size: 13px;
  font-weight: 400;
  max-width: 100%;
}

.mail-folder__text {
  font-size: 13px;
  color: $evi-headline-color;
  max-width: 100%;
}
</style>
