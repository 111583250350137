<template>
  <div class="mail-filtration">
    <div class="mail-filtration__headline">
      <div class="mail-filtration__title">
        {{ title }}
      </div>
      <div class="mail-filtration__search">
        <v-text-field
            class="mail-search"
            color="#44D370"
            solo
            placeholder="Поиск"
            height="100%"
            v-model="searchMessage"
        >
          <template v-slot:prepend-inner>
            <img :src="require('@/assets/img/chat/search.svg')" alt="">
          </template>
        </v-text-field>
        <v-menu offset-y :close-on-content-click="false" v-model="menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#44D370" class="evi-button-filter-calendar mr-6 ml-6" :class="isActiveFilter" v-bind="attrs" v-on="on">
              <div class="evi-button-filter-calendar__count" v-if="isActiveFilter">{{filterCount}}</div>
              <img width="18" v-else-if="!isDark" :src="require('@/assets/img/common/sort-white.svg')" alt="">
              <img width="18" v-else :src="require('@/assets/img/common/sort.svg')" alt="">
            </v-btn>
          </template>
          <v-list dense class="settings-menu">
            <v-list-item-group>
              <v-list-item>
                <v-list-item-title>
                  <v-text-field
                      label="От кого"
                      placeholder="Введите email"
                      class="evi-text-field"
                      color="#44D370"
                      v-model="filter.from"
                  />
                  <div class="d-flex justify-space-between align-center">
                    <v-btn class="evi-button-green mr-5" @click="setFilter(false)">
                      Сбросить
                    </v-btn>
                    <v-btn class="evi-button-green evi-button-green--fill" @click="setFilter(true)">
                      Применить
                    </v-btn>
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mail-filtration__pagination">
      <div class="d-flex align-center"
           v-if="hasRight('mail.action')">
        <v-checkbox
            color="green"
            class="mail-filtration__checkbox"
            hide-details
            v-model="selectedAll"
            :indeterminate="indeterminateCheckbox"
        >
        </v-checkbox>
        <div class="mail-filtration__actions d-flex">
          <div>
            <v-menu
                bottom
                close-on-click
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon :disabled="!selectedMessages.length"  v-bind="attrs" v-on="on">mdi-menu-down</v-icon>
              </template>
              <v-list class="mail-filtration-menu">
                <v-list-item class="mail-filtration-menu__item" @click="action('seen')">
                  <v-list-item-icon class="evi-sidebar__icon">
                    <img width="17" :src="require(`@/assets/img/mail/read.svg`)" alt=""/>
                  </v-list-item-icon>
                  <v-list-item-title>Отметить как прочитанное</v-list-item-title>
                </v-list-item>
                <v-list-item class="mail-filtration-menu__item" @click="action('unseen')">
                  <v-list-item-icon class="evi-sidebar__icon">
                    <img width="17" :src="require(`@/assets/img/mail/unread.svg`)" alt=""/>
                  </v-list-item-icon>
                  <v-list-item-title>Отметить как непрочитанное</v-list-item-title>
                </v-list-item>
                <v-list-item class="mail-filtration-menu__item" @click="removeMessagesModal = true">
                  <v-list-item-icon class="evi-sidebar__icon">
                    <img width="17" :src="require(`@/assets/img/mail/trash.svg`)" alt=""/>
                  </v-list-item-icon>
                  <v-list-item-title class="red--text">Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <span class="mail-filtration__selected">
          Выбрано: {{ selectedMessages.length }}
        </span>
        </div>
      </div>
      <div class="mail-filtration__info">
        <span class="mr-4">
          {{ firstShowBy }}-{{ maxShowBy }} из {{ messagesTotal }}
        </span>
        <div>
          <v-icon :disabled="!firstShowBy || firstShowBy === 1" @click="$emit('openPage', 'prev')" class="pointer">mdi-chevron-left</v-icon>
          <v-icon :disabled="maxShowBy >= messagesTotal" @click="$emit('openPage', 'next')" class="pointer">mdi-chevron-right</v-icon>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="removeMessagesModal"
        max-width="600"
        @close="removeMessagesModal = false"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление выбранных писем
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить {{ messagesToRemoveCount }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeMessagesModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeMessage"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {pluralize} from "@/tools/Helpers";
import {hasRight} from "../../utils/access/hasRight";

export default {
  name: "MailFiltration",
  props: {
    title: String,
    selectedMessages: Array,
    page: Number,
    perPage: Number,
    folder: Object,
  },
  data() {
    return {
      selectedAll: false,
      searchMessage: '',
      removeMessagesModal: false,
      timerId: null,
      filter: {
        from: '',
      },
      menu: false,
    }
  },
  computed: {
    ...mapState("mail", ["messages", "messagesTotal"]),
    isDark () {
      return this.$vuetify.theme.dark;
    },
    messagesToRemoveCount() {
      let employees = ['письмо', 'письма', 'писем'];

      return pluralize(this.selectedMessages.length, employees);
    },
    maxShowBy() {
      if (this.perPage * this.page > this.messagesTotal) {
        return this.messagesTotal;
      }
      return this.perPage * this.page;
    },
    firstShowBy() {
      if (this.messages.length > 0) {
        return 1 + this.perPage * (this.page - 1);
      }
      return 0;
    },
    indeterminateCheckbox () {
      return !(this.selectedMessages.length === this.messages.length || !this.selectedMessages.length);
    },
    filterCount () {
      let count = 0;

      for (const key in this.filter) {
        if (this.filter[key]) {
          count++;
        }
      }

      return count;
    },
    isActiveFilter () {
      if (this.filterCount) {
        return 'filter-active';
      }
      return '';
    },
  },
  methods: {
    hasRight: hasRight,
    ...mapActions({
      mailAction: 'mail/mailAction',
      setFolderIndicator: 'mail/setFolderIndicator',
    }),
    action (action) {
      if (action === 'unseen') {
        const seenMessages = this.selectedMessages.filter(i => !i.unseen);
        this.setFolderIndicator({folder: this.folder, unseen: this.folder?.status?.unseen + seenMessages.length});
      } else if (action === 'seen') {
        const unseenMessages = this.selectedMessages.filter(i => i.unseen);
        const unseenCount = this.folder?.status?.unseen;
        if (unseenCount) {
          this.setFolderIndicator({folder: this.folder, unseen: this.folder?.status?.unseen - unseenMessages.length});
        }
      }
      this.mailAction({
        id: this.$route.params.mailId,
        body: {
          uids: this.selectedMessages.map((i) => i.uid),
          path: this.$route.params.folder,
          action: action,
        }
      }).then(() => {
        this.$emit('updateMessages');
        this.$emit('selectAll', null);
      });
    },
    removeMessage () {
      this.action('delete');
      this.removeMessagesModal = false;
    },

    setFilter (val) {
      if (!val) {
        this.filter.from = '';
      }
      const query = {...this.$route.query, ...this.filter};
      query.page = 1;
      this.$router.replace({
        query: query
      }).catch(() => {
      });
      this.menu = false;
    },
  },
  mounted () {
    if (this.$route.query.search) {
      this.searchMessage = this.$route.query.search;
    }
    if (this.$route.query.from) {
      this.filter.from = this.$route.query.from;
    }
  },
  watch: {
    selectedAll (val) {
      this.$emit('selectAll', val);
    },
    'selectedMessages.length': {
      handler (val) {
        if (val === this.messages.length) {
          this.selectedAll = true;
        }
        if (!val) {
          this.selectedAll = false;
        }
      }
    },
    searchMessage (val) {
      if (val !== this.$route.query.search) {
        if (this.timerId)
          clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          const query = {...this.$route.query};

          if (val) {
            query.search = val;
          } else {
            delete query.search;
          }
          query.page = 1;
          this.$router.replace({
            query: query
          }).catch(() => {
          });
        }, 500);
      }
    },
  }
}
</script>


<style lang="scss">
.mail-filtration {
  &__headline {
    display: flex;
    height: 51px;

    & > div {
      width: 50%;
      border-bottom: 1px solid #DCE1E6;

      &:nth-of-type(1) {
        border-right: 1px solid #DCE1E6;
      }
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    padding: 17px 23px;
    display: flex;
    align-items: center;
  }

  &__search {
    padding-left: 5px;
    display: flex;
    align-items: center;
  }

  &__pagination {
    padding: 17px 23px;
    border-bottom: 1px solid #DCE1E6;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;
    box-sizing: border-box;
  }

  &__checkbox {
    margin-top: 0;
    padding-top: 0;

    .v-input__slot {
      margin-bottom: 0;
    }

    .v-input--selection-controls__input {
      height: 10px;
      width: 10px;
      margin-right: 5px;

      i {
        color: #444746;
      }
    }
    &.v-input--indeterminate {
      i {
        color: #4CAF50;
      }
    }

    .v-input--selection-controls__ripple {
      left: -18px;
    }
  }

  &__selected {
    font-size: 14px;
    color: #808386;
    display: flex;
    align-items: center;
  }

  &__actions {
    .v-icon {
      color: $evi-headline-color;
      margin-right: 13px;
      cursor: pointer;
    }
  }

  &__info {
    color: #5E5E5E;
    font-size: 14px;
    display: flex;
    margin-right: -17px;
    margin-left: auto;
  }
}

.mail-filtration-menu {
  &__item {
    cursor: pointer;
    transition: 0.3s;
    min-height: 33px;
    .v-list-item__title {
      font-size: 13px;
    }
  }
}
</style>
